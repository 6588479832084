import React, { Fragment } from "react"

/**
 * Shadow me to add your own bio content
 */

const BioContent = () => (
  <Fragment>
    相信未来，保持理智
    <br />
    认真生活，Never Done
  </Fragment>
)

export default BioContent
