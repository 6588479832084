/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, css } from "theme-ui"
import clsx from 'clsx'
import { SkipNavLink } from "@reach/skip-nav"
import Bio from "./bio"
import { Grid } from "./grid"
import { H1 } from "./typography"

export const CATEGORY_MAP = {
  newsletter: 'newsletter',
  koaWiki: 'koa-wiki',
  about: 'about'
}

const rootPath = `${__PATH_PREFIX__}/`
const LINKS = [
  {name: '文章', to: '/'},
  {name: [CATEGORY_MAP.newsletter], to: `/${CATEGORY_MAP.newsletter}`},
  {name: 'Koa-Wiki', to: `https://www.yuque.com/hucheng/od892d`},
  {name: '关于', to: '/about'},
]

function NavLink({
  location,
  to,
  ...rest
}) {
  const isSelected =
    to === location.pathname || location.pathname.startsWith(`${to}/`)

  return (
    <li className="px-5 py-2">
      <Link
        prefetch="intent"
        className={clsx(
          'underlined focus:outline-none block whitespace-nowrap text-lg font-medium hover:text-team-current focus:text-team-current',
          {
            'active text-team-current': isSelected,
            'text-secondary': !isSelected,
          },
        )}
        to={to}
        {...rest}
      />
    </li>
  )
}

const Title = ({ children, location }) => {
  return (
    <H1>
     {children}
    </H1>
  )

}

const Header = ({ children, title, ...props }) => (
  <div className="px-5vw py-9 lg:py-12">
    <nav className="text-primary grid lg:grid-cols-2 md:grid-cols-1 gap-4">
      <div className="flex justify-center">
        <Link
          prefetch="intent"
          to="/"
          className="text-primary underlined focus:outline-none block whitespace-nowrap text-2xl font-medium transition"
        >
        <Title {...props}>{title}</Title>
        </Link>
      </div>

      <ul className="flex justify-center items-center">
        {LINKS.map(link => (
          <NavLink key={link.to} location={props.location} to={link.to}>
            {link.name}
          </NavLink>
        ))}
      </ul>
    </nav>
   
  </div>  

)

export default Header
