import React, { Fragment } from "react"
import { IconLink } from './icon-link'
import { RssIcon } from './icons/rss-icon'
import { GithubIcon } from './icons/github-icon'
import { TwitterIcon } from './icons/twitter-icon'
import { H4, H6 } from "./typography"
const externalLinks = {
  github: 'https://github.com/hucheng91',
  twitter: 'https://twitter.com/hucheng9110',
  rss: ''
}
function AboutSection() {
  return (
    <div>
      {/* <Bio></Bio> */}
      <div className="text-secondary mt-6 flex items-center justify-between gap-4 xl:flex-col xl:items-start">
        <div className="flex gap-4">
          <IconLink href={externalLinks.github}>
            <GithubIcon size={32} />
          </IconLink>
          <IconLink href={externalLinks.twitter}>
            <TwitterIcon size={32} />
          </IconLink>
          {/* <IconLink href={externalLinks.rss}>
            <RssIcon size={32} />
          </IconLink> */}
        </div>
      </div>
    </div>
  )
}

const Footer = ({ children }) => (
  <footer
    className="relative  mx-10vw border-t pb-10 border-gray-200 dark:border-gray-600 flex justify-center"
  >
    <div className="w-full">
      {children}
      <div className="flex justify-center">
        <div className="">
          <AboutSection />
        </div>
      </div>
      <div className="flex justify-center flex-col  pt-2 text-secondary text-center text-sm">
        <p>
        本站全部文字在<a className="text-red font-bold" href="https://creativecommons.org/" target="_blank" >知识共享许可协议</a>之条款下提供
        </p>
        <p>© 2022~ created by hc</p>
      </div>
    </div>
  </footer>
)
export default Footer
