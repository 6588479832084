import * as React from "react"

import { Helmet } from "react-helmet"
import { css, Themed } from "theme-ui"
import { SkipNavContent } from "@reach/skip-nav"
import Header from "./header"
import "./layout.css"


const Layout = ({ children, ...props }) => {

  return (
    <Themed.root>
      <Helmet>
        {/* <link rel="stylesheet" href={webfontURL} /> */}
        <html className="dark set-color-team-current-blue" ></html>
        <body className="bg-white transition duration-500 dark:bg-gray-900" />
        <meta name="referrer" content="no-referrer"/>
      </Helmet>
      <div className="relative  mx-auto max-w-8xl">
        <Header {...props} />
        <SkipNavContent />
        {children}
      </div>

    </Themed.root>
  )
}

export default Layout
